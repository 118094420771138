<template>
  <div class="bg">

    <!---订单详情--->
      <div class="orderInfo">
         <div class="order-info">
            <div>合同编号：{{orderId}}</div>
            <div v-html="result.step_status_text"></div>
         </div>
         <div class="order-address">
            <!-- <div class="form">{{result.from_county.split('/')[1]}}</div>
            <div><img style="width:60px" src="@/assets/back.png"></div>
            <div class="form">{{result.to_county.split('/')[1]}}</div> -->
         </div>

        
      </div>
   

    <!---订单详情--->
   <el-table
    :data="tableData"
    style="width: 100%;border-radius:10px;"
    class="no-header-table">
    <el-table-column
      prop="name"
      width="180">
    </el-table-column>

    <el-table-column
      prop="value"
     width="200">
    </el-table-column>



  </el-table>
  <div class="call">
      <div class="call-people">
        
        <!-- <div>
          <svg t="1731383035362" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1599" width="20" height="20"><path d="M256 85.333333h512c64.8 0 117.333333 52.533333 117.333333 117.333334v618.666666c0 64.8-52.533333 117.333333-117.333333 117.333334H256c-64.8 0-117.333333-52.533333-117.333333-117.333334V202.666667c0-64.8 52.533333-117.333333 117.333333-117.333334z m0 64a53.333333 53.333333 0 0 0-53.333333 53.333334v618.666666a53.333333 53.333333 0 0 0 53.333333 53.333334h512a53.333333 53.333333 0 0 0 53.333333-53.333334V202.666667a53.333333 53.333333 0 0 0-53.333333-53.333334H256z m170.666667 618.666667a32 32 0 0 1 0-64h170.666666a32 32 0 0 1 0 64H426.666667z" fill="#000000" p-id="1600"></path></svg>
        </div> -->
        <div class="call-people-text"><a  :href="'tel:'+phone" >联系电话：{{phone}}</a></div>
      </div>
      
  </div>
 
  <div class="step-status">
      <!-- https://jinbo.api.cmgn.cc/api/common/upload -->
      <el-upload
      class="upload-demo"
      action="https://jinbo.api.cmgn.cc/api/common/upload"
      :on-preview="handlePreview"
      accept="image/*"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :file-list="fileList"
      :on-exceed="handleExceed"
      multiple
      :limit="30"
      list-type="picture">
      <el-button size="small" type="primary" :disabled="step_status >= 8">上传验车照片</el-button>
      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb,最多30张图片</div>
    </el-upload>
  </div>
    <div class="buy">
      <el-button  type="primary" class="buy-order" @click="over" :disabled="step_status >= 8"  v-if="step_status < 8">代驾司机完成订单操作</el-button>
      <el-button  type="primary" class="buy-order" @click="over" :disabled="step_status >= 8"  v-if="step_status >= 8">已完成</el-button>
    </div>
   
    <!-- <div style="padding-bottom:50px;"></div>
    <ToolBar :activeItem="'order'" ></ToolBar> -->
  </div>
  
</template>

<script>
// import ToolBar from '@/components/Toolbar.vue'
import api from '../../api';
export default {
   components:{
      // ToolBar
    },
  data() {
    return {
      status_step:1,
      status:1,//当前状态
      searchQuery: '',
      imageUrlDj: '',  // 图片地址
      previewVisibleDj: false,  // 控制是否显示图片组件
      orderId:"",
      tableData: [],
      result:[],
      stepSattus:[],
      phone:"",
      fileList: [],
      step_status:0
    };
  },
  mounted() {
    if(this.$route.query.order_id){
      this.orderId = this.$route.query.order_id;
    }
    this.init();
  },
  methods: {
    over() {
        this.$confirm('完成订单操作?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '确认!'
          // });
          if(this.fileList.length<=0){
              this.$message({
                type: 'info',
                message: '请先上传验车照片'
              }); 
          }else{
            this.sendOver();
          }
         
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消'
          // });          
        });
    },
    async sendOver(){
        try {
          var form={
            "fileList":this.fileList,
            "orderId":this.orderId
          };
          const response = await api.post('/api/order/over',form);
          this.$message.success(response.msg);
          window.location.reload();
        } catch (error) {
          //this.$message.error('获取信息失败请联系管理员');
            //window.location.reload();
        }
      //{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}

    },
    handleRemove(file) {
          // 当从界面上移除文件时，从fileList中移除对应的文件
          // 根据file.uid从fileList中移除对应的文件
          const index = this.fileList.findIndex(f => f.uid === file.uid);
          if (index > -1) {
            this.fileList.splice(index, 1);
          }
    },
    handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 30 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
    handlePreview(file) {
        console.log(file);
    },
    beforeUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt500K = file.size / 8192 <= 64*10;

      if (!isJPGorPNG) {
        this.$message.error('只能上传 JPG/PNG 文件!');
      }
      if (!isLt500K) {
        this.$message.error('图片大小不能超过 5M!');
      }

      return isJPGorPNG && isLt500K;
    },
    // handleSuccess(response, file) {
     
    //   // 如果上传成功，将fullurl添加到fileList中
    //   if (response.code === 1) {  // 假设code为1表示成功
    //     const newFile = {
    //       name: file.name,
    //       url: response.data.fullurl,
    //       uid: file.uid
    //     };
    //      // 检查fileList中是否已存在该文件
    //     const existingFileIndex = this.fileList.findIndex(f => f.uid === file.uid);
    //     if (existingFileIndex !== -1) {
    //       // 更新现有文件
    //       console.log("更新现有文件");
    //       this.fileList[existingFileIndex] = newFile;
    //     } else {
    //       // 添加新文件
    //       console.log("添加新文件");
    //       this.fileList.push(newFile);
    //     }
    //   } else {
    //     console.log('上传失败');
    //     this.$message.error('上传失败: ' + response.msg);
    //   }
    // },
    handleSuccess(response, file, fileList) {
    //等待所有文件都上传完成，这里注意fileList是所有的文件（包含已上传的）
        if(fileList.every(it => it.status == 'success')) { 
            console.log(fileList);
            fileList.map(item => {
            //只push新上传的 （带有response，response为接口返回 ）
              item.response &&this.fileList.push({name:item.name,url:item.response.data.fullurl,uid: item.uid})
            })
            //console.log( this.fileList);
          }
    },
    showPreviewDj() {
      this.previewVisibleDj = true;
    },
    async init() {
        try {
          const response = await api.post('/api/order/dispatch?order_id='+this.orderId);
          this.tableData=response.data.info;
          this.phone=response.data.phone;
          this.fileList=response.data.img;
          this.step_status=response.data.step_status;
         
        } catch (error) {
          //this.$message.error('获取信息失败请联系管理员');
            //window.location.reload();
        }

    },


 
    // 输入时处理搜索的回调
    handleSearch(value) {
      console.log('搜索输入内容:', value);
      // 可以在这里执行实时搜索或自动补全逻辑
    },
    // 点击搜索按钮时执行的操作
    performSearch() {
      console.log('搜索内容:', this.searchQuery);
      // 在这里添加搜索逻辑，比如向后端发起请求
    }
  }
};
</script>

<style>
  .see-hetong{text-decoration: none; color: #ffffff;}
  .step-status-text{
    font-size: 15px; font-weight: bold;
  }
  
  .el-steps{ margin-top:15px;}
  .step-status{
     background-color: #ffffff; padding: 15px; margin-top:15px; 
  }
  .call-people-text{font-size: 15px; font-weight: bold; padding-top:5px;padding-left:5px;}
  .call-people-text a{ text-decoration: none; color: #000000;}
  .pay-text{font-size: 15px; font-weight: bold;padding-top:5px;padding-left:5px;}
  .call-people{display: flex; }
  .pay{display: flex;}
  .call{display: flex;justify-content: space-around; background-color: #ffffff; padding: 15px;}
 .no-header-table .el-table__header-wrapper {
   display: none;
 }
  .search{ margin-bottom: 15px;}
  .buy{display:flex;justify-content: space-around;}
  .buy-order{ width: 200px; position:fixed;bottom: 100px; margin: auto; text-align: center; }
  .buy-order a{ text-decoration: none; color: #ffffff;}
  .order-more {display: flex;justify-content: space-around; padding: 15px; text-align: center;font-size: 16px;font-weight: bold;}
   .order-more  a{   text-decoration: none; color: #000000;}
  .order-people{display:flex;flex-direction: column; padding: 15px; border-bottom:#e5e5e5 1px solid ;}
   .order-people p{display:inline;}
  .form{font-size: 20px;font-weight: bold; padding-top: 15px;}
  .bg{background-color: #ececec; min-height: 900px; padding: 15px;}
  .orderInfo{background-color: #ffffff; border-radius: 10px; margin-bottom: 15px;}
  .order-info{display:flex;justify-content: space-between; border-bottom:#000000 1px solid ;padding: 15px;}
 .order-address{display:flex;justify-content: space-around; padding-top: 10px;}
 
  </style>